import React from 'react'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import PageFooter from '../components/sectionFooter'

import Container from '../components/container'
import PageHeader from '../components/pageHeader'
import Button from '../components/button'
import SocialIcons from '../components/social'
import styles from '../components/intro.module.scss'
import gatsbyImageData from '../components/gatsbyImageData'
import Seo from '../components/seo'

export default ({ data, ...rest }) => {
  const { background, hashtag } = data.datoCmsIntroSection

  return (
    <Layout>
      <Seo title="Page does not exist" pathname={rest.location.pathname} />
      <section className={styles.section}>
        <Img
          fluid={{
            ...gatsbyImageData({
              image: background,
              sizes: {
                mobile: `calc(100vh * ${background.fluid.aspectRatio})`,
                tablet: `calc(100vh * ${background.fluid.aspectRatio})`,
                desktop: `calc(100vh * ${background.fluid.aspectRatio})`,
              },
              srcSet: ['1280', '1600', '1920', '2240', '2560'],
            }),
          }}
          alt={background.alt || 'Gymradio'}
          Tag="figure"
          className={styles.background}
          style={{ position: '' }}
          imgStyle={{ objectFit: '', objectPosition: '' }}
        />

        <div className={styles.inner}>
          <PageHeader />
          <Container style={{ minHeight: '1px' }}>
            <div className={styles.content}>
              <h1 className={styles.title}>
                <em>Ups</em>
                <strong>nothing here</strong>
              </h1>
              <h2>Page does not exist</h2>
              <Button href="/" variant="secondary" size="large">
                Back to homepage
              </Button>
            </div>
          </Container>
          <footer className={styles.footer}>
            <Container>
              <strong className={styles.hashtag}>{hashtag}</strong>
              <SocialIcons />
            </Container>
          </footer>
        </div>
      </section>

      <PageFooter />
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsIntroSection {
      hashtag
      background {
        fluid(maxWidth: 2560) {
          ...GatsbyDatoCmsFluid
        }
        alt
        url
      }
    }
  }
`
